<template>
  <section class="section">
    <div class="container">
      <Breadcrumbs :breadcrumbs="breadcrumbs" />
      <ForumCard v-if="forum" :forum="forum" is-header class="boxed elevation-1 mb-3 p-2 my-2" />
      <TopicList v-if="forum" :forum-id="forum.id" />
    </div>
  </section>
</template>

<script>
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import ForumCard from "@/components/forums/ForumCard.vue";
import TopicList from "@/components/forums/TopicList.vue";
import { FORUMS_GET_FORUM_BY_PK } from "@/models/forums/operations.gql";

export default {
  name: "Forum",
  components: {
    Breadcrumbs,
    ForumCard,
    TopicList
  },
  data() {
    return {
      forum: null,
      topic: null,
      topics: [],
      isShowingAddTopic: false,
      sortOptions: [
        {
          title: "Top Topics",
          icon: "star",
          orderBy: { posts_aggregate: { count: "desc" } },
          condition: true
        },
        {
          title: "Latest",
          icon: "exchange-alt",
          customClass: "fa-rotate-90",
          orderBy: { updated_at: "desc" },
          condition: true
        },
        {
          title: "My Activity",
          icon: "user",
          orderBy: { updated_at: "asc" },
          where: { _eq: this.$auth.isAuthenticated ? this.$auth.user.id : {} },
          condition: this.$auth.isAuthenticated
        }
      ],
      selectedSortOption: 0
    };
  },
  computed: {
    breadcrumbs() {
      const result = [];

      result.push({
        to: { name: "forum_categories" },
        label: "Forums"
      });

      if (this.forum) {
        result.push({
          to: { name: "forum_categories" },
          label: this.forum.category.title
        });

        result.push({
          to: {
            name: "forum_topics",
            params: {
              slug: this.forum.slug,
              forum_id: this.forum.id
            }
          },
          label: this.forum.title
        });
      }

      return result;
    }
  },
  apollo: {
    forum: {
      query: FORUMS_GET_FORUM_BY_PK,
      variables() {
        return {
          forum_id: this.$route.params.forum_id
        };
      },
      error() {
        this.display404Page();
      },
      update({ forum }) {
        return forum || this.display404Page();
      }
    }
  }
};
</script>
