<template>
  <div v-if="!isDeleted" class="forum-card">
    <div v-if="isHeader" class="expanded-card is-flex">
      <div class="is-center">
        <div v-if="forum">
          <div class="is-flex is-align-items-center" style="min-width: 0;">
            <div class="expanded-card--title is-size-4 has-text-weight-medium">
            {{ forum.title }}
            </div>
          </div>
        </div>
        <TipTap
          v-if="!isEditing"
          v-model="form.body"
          :is-preview="false"
          class="has-text-grey700 mb-2 my-1"
        />
      </div>

      <!-- Forum details -->
      <div v-if="forum && !isEditing" class="forum-card-details">
        <b-taglist>
          <b-tag type="is-danger is-light has-text-grey-dark mb-0">
            <b-icon icon="star" class="is-align-self-center" />
            <span class="mr-1">Last activity</span>
            <InlineDate
              text-type="light"
              :d="
                forum.topics_aggregate.aggregate.max.updated_at ||
                  forum.updated_at
              "
            />
          </b-tag>
        </b-taglist>
      </div>
    </div>
    <div v-else class="expanded-card">
      <div class="is-center">
        <!-- If editing, show input -->
        <div v-if="isEditing">
          <b-field
            :type="{ 'is-danger': $v.form.title.$invalid }"
            :message="{ 'Title is required': !$v.form.title.required }"
            label="Forum Title"
            class="mb-1"
          >
            <input
              class="input"
              placeholder="My amazing forum"
              :value="form.title"
              @input="handleTitleInput"
            />
          </b-field>
        </div>
        <div v-else-if="forum">
          <div class="is-flex is-align-items-center" style="min-width: 0;">
            <div
              class="expanded-card--title is-size-4 has-text-weight-medium is-hover-grow"
              @click="onForumCardClick"
            >
              {{ forum.title }}
            </div>
          </div>
        </div>
        <p v-if="forum && !isEditing" class="my-1">
          {{ forum.body_text | truncate(180) }}
        </p>
        <TipTap
          v-else
          v-model="form.body"
          :type="$v.form.body.required ? '' : 'is-danger'"
          :message="
            $v.form.body.required ? '' : 'Forum description is required.'
          "
          :is-preview="!isEditing"
          :show-edit-controls="isEditing"
          :editable="isEditing"
          :confirm-enabled="() => !$v.form.$invalid"
          @edit-cancelled="onCancelForum"
          @edit-confirmed="onConfirmForum"
        />
      </div>

      <!-- Forum details -->
      <div v-if="forum && !isEditing" class="forum-card-details">
        <b-taglist>
          <b-tag type="is-danger is-light has-text-grey-dark mb-0">
            <b-icon icon="star" class="is-align-self-center" />
            <span class="mr-1">Last activity</span>
            <InlineDate
              text-type="light"
              :d="
                forum.topics_aggregate.aggregate.max.updated_at ||
                  forum.updated_at
              "
            />
          </b-tag>
        </b-taglist>
        <b-taglist>
          <b-tag type="is-success is-light has-text-grey-dark">
            <b-icon icon="user-check" />
            <span>{{ forum.topics_aggregate.aggregate.author_count }}</span>
          </b-tag>
          <b-tag type="is-warning is-light has-text-grey-dark">
            <b-icon icon="align-left" />
            <span
              >{{ forum.topics_aggregate.aggregate.count }}
              {{
                `Post${forum.topics_aggregate.aggregate.count !== 1 ? "s" : ""}`
              }}
            </span>
          </b-tag>
          <b-dropdown
            v-if="ellipsesEnabled"
            ref="dropdown"
            aria-role="list"
            position="is-top-left"
          >
            <template #trigger>
              <b-button
                v-if="!isEditing"
                type="is-ghost is-small has-text-grey-dark"
                icon-left="ellipsis-h"
              />
            </template>
            <!-- edit -->
            <b-dropdown-item class="p-0 mb-1 has-text-centered">
              <b-button
                type="is-ghost"
                label="Edit"
                @click="isEditing = true"
              />
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userCanDelete"
              class="p-0 mb-1 has-text-centered"
            >
              <b-button type="is-ghost" label="Delete" @click="confirmDelete" />
            </b-dropdown-item>
          </b-dropdown>
        </b-taglist>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CREATE_FORUMS_FORUM,
  UPDATE_FORUMS_FORUM
} from "@/models/forums/operations.gql";
import { required } from "vuelidate/lib/validators";
import InlineDate from "@/components/common/InlineDate.vue";
import TipTap from "@/components/forms/TipTap.vue";

export default {
  name: "ForumCard",
  components: {
    InlineDate,
    TipTap
  },
  props: {
    forum: {
      type: Object,
      default: null
    },
    category: {
      type: Object,
      default: null
    },
    isHeader: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      isEditing: false,
      form: {
        title: "",
        body: null
      }
    };
  },
  validations: {
    form: {
      title: {
        required
      },
      body: {
        required
      }
    }
  },
  computed: {
    hasChanged() {
      if (this.forum) {
        return this.form.title !== this.forum.title;
      }
      return true;
    },
    isDeleted() {
      return this.forum && this.forum.deleted_at;
    },
    userCanDelete() {
      return this.forum && !this.isDeleted && this.$auth.isAdmin;
    },
    ellipsesEnabled() {
      return (
        this.forum !== null &&
        this.$auth.isAuthenticated &&
        (this.$auth.user.id === this.forum.author.id || this.$auth.isAdmin)
      );
    }
    // ,  --not restoring forums for now
    // userCanRestore() {
    //   return this.forum && this.isDeleted && this.$auth.isAdmin;
    // }
  },
  mounted() {
    if (this.forum !== null) {
      // There is an existing forum
      // Populate the form with existing data
      this.form.title = this.forum.title;
      this.form.body = Object.assign({}, this.forum.body);
    } else {
      // We are creating a new forum
      // so set editing to true
      this.isEditing = true;
    }
  },
  methods: {
    onForumCardClick() {
      this.$router.push({
        name: "forum_topics",
        params: {
          slug: this.forum.slug,
          forum_id: this.forum.id
        }
      });
    },
    handleTitleInput(event) {
      const value = event.target.value;
      this.form.title = value.replace(/\p{Extended_Pictographic}/gu, "");
      this.$forceUpdate();
    },
    onCancelForum() {
      this.isEditing = false;

      if (this.forum) {
        this.form = {
          ...this.forum
        };
      }
      this.$emit("cancel");
    },
    onConfirmForum(body) {
      Object.assign(this.form, { body });

      if (this.forum) {
        this.onUpdate();
      } else {
        this.onCreate();
      }
    },
    onCreate() {
      const { title, body } = this.form;
      this.$apollo
        .mutate({
          mutation: CREATE_FORUMS_FORUM,
          variables: {
            category_id: this.category.id,
            title,
            body
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);
          this.$emit("created", result);
          this.isEditing = false;
          this.$buefy.snackbar.open({
            message: result.title ? `Forum ${result.title} created` : "Forum created",
            type: "is-success"
          });
        });
    },
    onUpdate() {
      const { title, body } = this.form;
      this.$apollo
        .mutate({
          mutation: UPDATE_FORUMS_FORUM,
          variables: {
            forum_id: this.forum.id,
            changes: {
              title,
              body
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);
          this.$emit("updated", result);
          this.isEditing = false;
          this.$buefy.snackbar.open({
            message: result.title ? `Forum ${result.title} updated` : "Forum updated",
            type: "is-success"
          });
        });
    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        message: "Are you sure you want to delete this forum?",
        onConfirm: () => {
          this.onDelete();
        }
      });
    },
    onDelete() {
      this.$apollo
        .mutate({
          mutation: UPDATE_FORUMS_FORUM,
          variables: {
            forum_id: this.forum.id,
            changes: {
              deleted_at: "now()"
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in delete", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);
          this.$emit("updated", result);
          this.isEditing = false;
          this.$buefy.snackbar.open({
            message: result.title ? `Forum ${result.title} deleted` : "Forum deleted",
            type: "is-success"
          });
        });
    }
  }
};
</script>

<style lang="scss">
.forum-card-details {
  height: 90%;

  @include desktop() {
    justify-content: flex-end;
  }
}

.tags {
  justify-content: end;
}
</style>
