<template>
  <section class="section">
    <div class="container">
      <Breadcrumbs :breadcrumbs="breadcrumbs" />

      <TopicCard
        v-if="topic !== null"
        id="topicRow"
        :topic="topic"
        is-header
        class="boxed elevation-1 mb-3 p-2 my-2"
      />

      <div>
        <!-- List Controls - Desktop -->
        <div class="is-flex mb-2">
          <div class="tabs is-danger is-medium mb-0">
            <ul>
              <li
                v-for="(option, index) in sortOptions"
                v-show="option.condition"
                :key="option.title"
                :class="{ 'is-active': selectedSortOption === index }"
              >
                <a :class="{ 'button discussions-button': suwMobile }" @click="selectedSortOption = index">
                  <b-icon
                    :icon="option.icon"
                    :custom-class="option.customClass"
                  />
                  <span class="button-label">{{ option.title }}</span>
                </a>
              </li>
            </ul>
          </div>
     
          <b-switch
            v-if="$auth.isAuthenticated"
            v-model="onlyShowMine"
            class=""
            style="margin-left: auto"
            size="is-small"
            type="is-warning"
          >
            My Comments
          </b-switch>
  
          <AuthButton
            class="is-align-items-center"
            :type="suwMobile ? 'discussions-button has-text-weight-semibold has-text-info ml-1' : 'has-text-weight-semibold has-text-info elevation-1'"
            :label="suwMobile ? '' : 'Create New Comment'"
            icon-left="plus-circle"
            :disabled="isShowingTopicReplyForm"
            @click="isShowingTopicReplyForm = true"
          >
            <template #dropdown="{ login }">
              <div
                class="is-flex is-justify-content-center is-align-items-center p-2"
              >
                <p class="mr-2">
                  Login Required
                </p>
                <b-button
                  label="Sign Up / Log in"
                  type="is-primary"
                  @click="login"
                />
              </div>
            </template>
          </AuthButton>
        </div>

        <EmptyState v-if="posts.length < 1 && !isShowingTopicReplyForm" class="my-2">
          No replies yet
        </EmptyState>

        <PostCard
          v-if="isShowingTopicReplyForm"
          class="my-2"
          :class="{ 'pl-2': !suwMobile }"
          :topic="topic"
          @created="isShowingTopicReplyForm = false"
          @cancel="isShowingTopicReplyForm = false"
        />

        <PostCard
          v-for="p in posts"
          :id="p.id"
          :key="p.id"
          :post="p"
          :topic="topic"
          :style="`margin-left: ${p.depth * 2}rem;`"
          class="my-2 boxed elevation-1"
          @reply-form-open="handlePostReplyOpen($event)"
          @reply-form-close="postShowingReplyForm = null"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import TopicCard from "@/components/forums/TopicCard.vue";
import PostCard from "@/components/forums/PostCard.vue";
import EmptyState from "@/components/common/EmptyState.vue";
import AuthButton from "@/components/common/AuthButton.vue";

import { FORUMS_GET_TOPIC_BY_PK, LIST_TOPIC_POSTS } from "@/models/forums/operations.gql";
import { format_title, slugify } from "../../filters";

export default {
  name: "Topic",
  components: {
    AuthButton,
    Breadcrumbs,
    PostCard,
    TopicCard,
    EmptyState
  },
  data() {
    return {
      topic: null,
      posts: [],
      isShowingTopicReplyForm: false,
      replyFormCloser: null, // an anonymous function closure that closes the currently open reply form
      isEditing: false, // user is currently editing the post
      sortOptions: [
        {
          title: "Top Comments",
          icon: "star",
          orderBy: { children_aggregate: { count: "desc" } },
          condition: true
        },
        {
          title: "Latest",
          icon: "exchange-alt",
          class: "has-text-grey400",
          customClass: "fa-rotate-90",
          orderBy: { created_at: "desc" },
          condition: true
        }
      ],
      selectedSortOption: 0,
      onlyShowMine: false
    };
  },
  computed: {
    breadcrumbs() {
      const result = [];

      if (this.topic) {
        if (this.topic.forum.event_template) {
          result.push({
            to: { name: "events" },
            label: "Events"
          });
          result.push({
            to: {
              name: "event_detail",
              params: {
                slug: slugify(this.topic.forum.event_template.upcoming_events[0]),
                event_id: this.topic.forum.event_template.upcoming_events[0].id
              }
            },
            label: format_title(this.topic.forum.event_template.upcoming_events[0])
          });
        } else {
          result.push({
            to: { name: "forum_categories" },
            label: "Forums"
          });
          result.push({
            to: { name: "forum_categories" },
            label: this.topic.forum.category.title
          });
          result.push({
            to: {
              name: "forum_topics",
              params: {
                slug: this.topic.forum.slug,
                forum_id: this.topic.forum.id
              }
            },
            label: this.topic.forum.title
          });
        }

        result.push({
          to: {
            name: "forum_topic",
            params: {
              slug: this.topic.slug,
              topic_id: this.topic.id
            }
          },
          label: this.topic.title
        });
      }

      return result;
    }
  },
  apollo: {
    topic: {
      query: FORUMS_GET_TOPIC_BY_PK,
      variables() {
        return {
          topic_id: this.$route.params.topic_id
        };
      },
      error() {
        this.display404Page();
      },
      update({ topic }) {
        //this.$log.debug("in update", topic);
        return topic;
      }
    },
    posts: {
      query: LIST_TOPIC_POSTS,
      variables() {
        return {
          topic_id: this.$route.params.topic_id,
          where: {
            parent_id: { _is_null: true },
            ...this.sortOptions[this.selectedSortOption].where,
            author_id: this.onlyShowMine ? { _eq: this.$auth.user.id } : {}
          },
          orderBy: this.sortOptions[this.selectedSortOption].orderBy
        };
      }
    }
  },
  methods: {
    handlePostReplyOpen(replyFormCloser) {
      if (this.replyFormCloser !== null) {
        this.replyFormCloser();
      }
      this.replyFormCloser = replyFormCloser;
    },
    handlePostReplyClose() {
      this.postShowingReplyForm = null;
    }
  }
};
</script>

<style lang="scss">
@import "../../scss/mixins";
.section {
  @include mobile {
    padding: 2rem 0.5rem;
  }
}
</style>
