<template>
  <HeaderPage
    title="Discussions"
    image-class="discussion-header"
    image="/img/discussion-header.jpeg.webp"
  >
    <template #summary>
      <strong>Welcome to the Shut Up & Write! Discussions!</strong>
      While participating here, please make sure you're abiding by our
      <router-link :to="{ name: 'code_of_conduct' }"
        >Code of Conduct</router-link
      >.
    </template>
    <template #body>
      <Section>
        <div
          v-if="$auth.isAdmin"
          class="is-flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap"
        >
          <b-button
            type="elevation-1 has-text-weight-semibold has-text-info"
            icon-left="plus-circle"
            label="Create New Category"
            :disabled="isShowingCategoryForm"
            @click="isShowingCategoryForm = !isShowingCategoryForm"
          />
          <b-switch
            v-model="isReorgEnabled"
            class="is-flex is-justify-content-flex-end"
            :disabled="!categories || categories.length < 2"
          >
            Reordering Mode
          </b-switch>
        </div>

        <EmptyState v-if="categories.length < 1" class="m-1">
          No Categories Found
        </EmptyState>

        <!-- New Category Form -->
        <div class="m-1">
          <CategoryCard
            v-if="isShowingCategoryForm"
            :category="null"
            @created="onCreatedCategory"
            @cancel="isShowingCategoryForm = false"
          />
        </div>

        <div class="m-1">
          <DropList
            ref="categoryList"
            :items="sortableCategories"
            @reorder="onReorder($event, sortableCategories)"
          >
            <template #item="{item, reorder}">
              <Drag
                :key="`'drag/${item.id}`"
                :data="item"
                :disabled="!isReorgEnabled"
                :drag-image-opacity="1.0"
                @dragstart="isDragging = true"
                @dragend="isDragging = false"
              >
                <template #default>
                  <CategoryCard
                    class="py-2"
                    :show-drag-handle="isReorgEnabled"
                    :is-reordering="reorder"
                    :category="item"
                    :show-forums="!isReorgEnabled"
                    @updated="onUpdatedCategory"
                    @forum-added="$apollo.queries.categories.refetch()"
                  />
                </template>
                <template #drag-image>
                  <div class="drag-image">
                    <b-icon icon="arrow-down" />
                  </div>
                </template>
              </Drag>
            </template>
          </DropList>
        </div>

        <div v-if="isReorgEnabled" class="buttons mt-2">
          <b-button type="is-warning" @click="resetSortOrder">
            Reset
          </b-button>
          <b-button type="is-success" @click="saveSortOrder">
            Save
          </b-button>
        </div>
      </Section>
    </template>
  </HeaderPage>
</template>

<script>
import dragAndDrop from "@/mixins/dragAndDrop.js";
import CategoryCard from "@/components/forums/CategoryCard.vue";
import EmptyState from "@/components/common/EmptyState.vue";
import HeaderPage from "@/components/common/HeaderPage.vue";
import Section from "@/components/common/Section.vue";
import {
  UPDATE_INSTANCE,
  FORUMS_CATEGORY_LIST
} from "@/models/forums/operations.gql";

export default {
  name: "Categories",
  components: {
    HeaderPage,
    Section,
    CategoryCard,
    EmptyState
  },
  mixins: [dragAndDrop],
  data() {
    return {
      isDragging: false, // true when re-arranging categories
      isShowingCategoryForm: false,
      categories: [],
      instance: null,
      sortableCategories: [], // proxy for apollo-provided `categories`
      isReorgEnabled: false // can users drag to re-order categories
    };
  },
  apollo: {
    categories: {
      query: FORUMS_CATEGORY_LIST,
      variables: {
        where: {
          instance_id: { _eq: 0 }
        }
      },
      update({ categories }) {
        if (categories.length === 0) {
          return categories;
        }

        this.instance = categories[0].instance;

        this.sortableCategories = this.orderByArray(
          categories,
          this.instance.category_ids
        );

        return categories;
      }
    }
  },
  methods: {
    resetSortOrder() {
      this.sortableCategories = this.orderByArray(
        this.categories,
        this.instance.category_ids
      );
      this.isReorgEnabled = false;
    },
    saveSortOrder() {
      let newOrder = this.sortableCategories.map(c => c.id);
      this.$apollo
        .mutate({
          mutation: UPDATE_INSTANCE,
          variables: {
            instance_id: this.instance.id,
            changes: {
              category_ids: `{${newOrder.join(",")}}`
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update on set order", result);
            this.sortableCategories.forEach(p =>
              cache.evict(cache.identify(p))
            );
            this.sortableCategories = [];
            cache.gc();
          }
        })
        .then(() => {
          this.sortableCategories = this.orderByArray(
            this.categories,
            newOrder
          );
          //this.$apollo.queries.prompts.refetch();
          this.isReorgEnabled = false;
          this.$buefy.snackbar.open({
            message: "Category Order Updated",
            type: "is-success"
          });
        });
    },
    onCreatedCategory(category) {
      this.$log.debug("Category Created", category);
      this.isShowingCategoryForm = false;
    },
    onUpdatedCategory(category) {
      this.$log.info("Category Updated", category);
      this.isShowingCategoryForm = false;
    }
  }
};
</script>

<style lang="scss">
.drag-image {
  background: $primary;
  color: white;
  padding: 0.5em;
  transform: translate(-50%, -50%);
}
</style>
