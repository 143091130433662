<template>
  <div
    v-if="!isDeleted"
    class="category-card"
    :class="{
      'is-reordering': isReordering,
      'is-editing': isEditing
    }"
  >
    <!-- Edit mode -->
    <div v-if="isEditing" class="p-1">
      <div
        class="is-flex is-justify-content-space-between is-align-items-flex-end is-flex-wrap-wrap"
      >
        <b-field
          :type="{ 'is-danger': $v.form.title.$invalid }"
          :message="{ 'Title is required': !$v.form.title.required }"
          label="Category title"
        >
          <b-input
            v-model="form.title"
            size="is-large"
            placeholder="Enter category name..."
            @keydown.native.enter="onConfirmCategory"
          />
        </b-field>

        <div class="buttons">
          <UndoConfirmButtons
            :confirm-enabled="!$v.form.$invalid"
            @undo="onCancelCategory"
            @confirm="onConfirmCategory"
          >
            <template #confirm>
              {{ category ? "Update category" : "Create category" }}
            </template>
          </UndoConfirmButtons>
        </div>
      </div>
    </div>

    <!-- View mode -->
    <div
      v-if="category && !isEditing"
      class="is-flex is-justify-content-space-between is-flex-wrap-wrap p-1"
    >
      <div class="is-flex is-align-items-center">
        <!-- Drag and toggle forums buttons -->
        <b-button
          v-if="showDragHandle"
          icon-left="grip-vertical"
          type="text"
          style="cursor: grab;"
          :disabled="!isReordering"
        />
        <b-button
          v-if="showForums"
          type="is-ghost"
          :icon-left="isShowingForums ? 'chevron-circle-down': 'chevron-circle-right'"
          size="is-medium"
          @click="isForumToggleActive = !isForumToggleActive"
        />

        <!-- Category title -->
        <span
          class="expanded-card--title is-size-2 is-size-3-desktop has-text-weight-semibold"
          >{{ category.title }}</span
        >
      </div>

      <!-- Category details and admin controls -->
      <div v-if="!showDragHandle" class="is-flex is-align-items-center">
        <!-- Add new forum button -->
        <b-button
          v-if="$auth.isAdmin"
          type="elevation-1 has-text-weight-semibold has-text-info mr-2"
          icon-left="plus-circle"
          label="Create New Forum"
          :disabled="isShowingForumForm"
          @click="isShowingForumForm = !isShowingForumForm"
        />
        <span class="is-size-4 has-text-weight-semibold"
          >{{ category.forums.length }} {{ "Forum" | pluralize(category.forums.length) }}</span
        >
        <b-dropdown ref="dropdown" aria-role="list" position="is-top-left">
          <template #trigger>
            <b-button
              style="border: none;"
              type="is-ghost is-small has-text-grey-dark"
              icon-left="ellipsis-h"
            />
          </template>
          <!-- delete -->
          <b-dropdown-item
            v-if="userCanDelete"
            class="p-0 mb-1 has-text-centered"
          >
            <b-button type="is-ghost" label="Delete" @click="confirmDelete" />
          </b-dropdown-item>
          <!-- edit -->
          <b-dropdown-item
            v-if="userCanEdit"
            class="p-0 mb-1 has-text-centered"
          >
            <b-button type="is-ghost" label="Edit" @click="toggleEditing" />
          </b-dropdown-item>
        </b-dropdown>


      </div>
    </div>

    <!-- New Forum form -->
    <div v-if="isShowingForumForm" class="forum-list mb-1">
      <ForumCard
        class="boxed p-2"
        :forum="null"
        :category="category"
        @created="onCreatedForum"
        @cancel="isShowingForumForm = false"
      />
    </div>

    <!-- Forum List -->
    <div
      v-if="category && category.forums && isShowingForums"
      class="forum-list"
    >
      <div>
        <ForumCard
          v-for="forum in category.forums"
          :key="forum.id"
          class="boxed elevation-1 mb-3 p-2"
          :forum="forum"
          @updated="onUpdatedForum"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UndoConfirmButtons from "@/components/common/UndoConfirmButtons.vue";
import ForumCard from "@/components/forums/ForumCard.vue";

import { required } from "vuelidate/lib/validators";

import {
  CREATE_FORUMS_CATEGORY,
  UPDATE_FORUMS_CATEGORY
} from "@/models/forums/operations.gql";

export default {
  name: "CategoryCard",
  components: {
    UndoConfirmButtons,
    ForumCard
  },
  props: {
    category: {
      type: Object,
      default: null
    },
    showForums: {
      type: Boolean,
      default: true
    },
    showDragHandle: {
      type: Boolean,
      default: false
    },
    isReordering: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isForumToggleActive: this.showForums,
      isShowingForumForm: false,
      isEditing: false,
      form: {
        title: ""
      }
    };
  },
  validations: {
    form: {
      title: {
        required
      }
    }
  },
  computed: {
    isShowingForums() {
      return this.showForums && this.isForumToggleActive;
    },
    isDeleted() {
      return this.category && this.category.deleted_at ? true : false;
    },
    userCanEdit() {
      return (this.category &&
        !this.isDeleted &&
        this.$auth.isAuthenticated &&
        this.$auth.isAdmin
      );
    },
    userCanDelete() {
     return (this.category &&
        !this.isDeleted &&
        this.$auth.isAuthenticated &&
        this.$auth.isAdmin
      );
    }
  },
  mounted() {
    if (this.category !== null) {
      // There is an existing category
      // Populate the form with existing data
      this.form.title = this.category.title;
    } else {
      // We are creating a new category
      // so set editing true
      this.isEditing = true;
    }
  },
  methods: {
    onConfirmCategory() {
      this.isEditing = false;
      if (this.category) {
        this.onUpdate();
      } else {
        this.onCreate();
      }
    },
    onCancelCategory() {
      this.isEditing = false;
      this.$emit("cancel");
    },
    onCreate() {
      const { title } = this.form;

      this.$apollo
        .mutate({
          mutation: CREATE_FORUMS_CATEGORY,
          variables: {
            instance_id: 0,
            title
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);

          this.$emit("created", result);

          this.isEditing = false;

          this.$buefy.snackbar.open({
            message: result.title ? `Category ${result.title} created` : "Category created",
            type: "is-success"
          });
        });
    },
    onUpdate() {
      const { title } = this.form;

      this.$apollo
        .mutate({
          mutation: UPDATE_FORUMS_CATEGORY,
          variables: {
            category_id: this.category.id,
            changes: {
              title
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);

          this.$emit("updated", result);

          this.isEditing = false;

          this.$buefy.snackbar.open({
            message: result.title ? `Category ${result.title} updated` : "Category updated",
            type: "is-success"
          });
        });
    },
    onDelete() {
      this.$apollo
        .mutate({
          mutation: UPDATE_FORUMS_CATEGORY,
          variables: {
            category_id: this.category.id,
            changes: {
              deleted_at: "now()"
            }
          },
          update: (cache, { data: { result } }) => {
            this.$log.info("in update", result);
            cache.evict(cache.identify(result));
            cache.gc();
          }
        })
        .then(({ data: { result } }) => {
          this.$log.info("in then", result);

          this.$emit("updated", result);

          this.isEditing = false;

          this.$buefy.snackbar.open({
            message: result.title ? `Category ${result.title} deleted` : "Category deleted",
            type: "is-success"
          });
        });
    },
    onCreatedForum(forum) {
      this.$log.debug("Forum Created", forum);
      this.isShowingForumForm = false;
    },
    onUpdatedForum(forum) {
      this.$log.info("Forum Updated", forum);
      this.isShowingForumForm = false;
    },
    toggleEditing() {
      this.isEditing = !this.isEditing;
      this.$refs.dropdown.toggle();
    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        message: "Are you sure you want to delete this category?",
        onConfirm: () => {
          this.onDelete();
        }
      });
    },
  }
};
</script>

<style lang="scss">
.category-card {
  &.is-reordering {
    border: 2px solid $primary;
    margin-top: -2px;
    margin-left: -2px;
    margin-bottom: -2px;
  }

  &.is-editing {
    border: 2px solid $primary;
    margin-top: -2px;
    margin-left: -2px;
    margin-bottom: -2px;
  }

  border-bottom: 1px solid $grey300;
}

.forum-list {
  @include desktop() {
    padding-left: 3.5rem;
  }
}
.button.is-ghost {
  &:hover {
    background: transparent !important;
    color: black;
  }
  color: $grey500;
  border: none !important;
}
</style>
